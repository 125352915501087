import { useContext, useEffect, useRef, useState } from 'react'
import { InformationIcon } from 'src/components/Icons/InformationIcon'
import { ProductContext } from 'src/components/restructure/product/contexts/product-context'
import { subscribe } from 'src/components/restructure/product/productEvents'

export function Notification() {
  const { specificationMustBeSelected } = useContext(ProductContext)
  const [showNotification, setShowNotification] = useState(false)

  useEffect(() => {
    subscribe('addToCart', () => setShowNotification(true))
    subscribe('hideSelectorNotification', () => setShowNotification(false))
  }, [setShowNotification])

  const element = useRef<HTMLParagraphElement | null>(null)

  useEffect(() => {
    element?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [])

  return (
    <>
      {showNotification && specificationMustBeSelected && (
        <p
          ref={element}
          className="dialog  desktop-caption-regular text-restructure-error flex items-start md:items-center mt-[-8px] gap-x-1 md:leading-[0.65rem]"
        >
          <InformationIcon color="#CC212A" /> Você precisa escolher um(a){' '}
          {specificationMustBeSelected?.field.name}
        </p>
      )}
    </>
  )
}
