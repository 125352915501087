import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { navigate } from 'gatsby'
import { useUserData } from 'src/components/contexts/UserDataContext'
import {
  toggleWishList,
  useCheckFavoriteProducts,
} from 'src/sdk/product/useWishlist'
import Icon from 'src/components/ui/Icon'
import { sendWishlistEvent } from 'src/utils/restructure/analytics/wishlistEvent'
import { mapperEventItem } from 'src/components/restructure/utils/mapperEventItem'

import { loginUrl } from '../../../../../../../store.config'

export function WishListButton() {
  const { userData } = useUserData()
  const { currentSku, product } = useContext(ProductContext)

  const { itemId, sellers } = currentSku

  const sellerName = sellers.find((seller) => seller.sellerDefault)?.sellerName

  const isInWishlist = useCheckFavoriteProducts(
    userData?.UserId ?? 'undefineduser',
    itemId
  )

  const EVENT_ITEM = mapperEventItem({ product, item: currentSku })

  return (
    <button
      className="p-4"
      onClick={(e) => {
        sendWishlistEvent(EVENT_ITEM)
        userData?.UserId
          ? toggleWishList({
              id: userData.UserId,
              itemId,
              seller: sellerName ?? '',
              e,
            })
          : navigate(
              `${loginUrl}?returnUrl=https://www.decathlon.com.br${window.location.pathname}`
            )
      }}
      aria-label="add or remove item of wishlist"
    >
      {isInWishlist ? (
        <Icon
          aria-label="Botão para remover produto da lista de favoritos"
          data-testid="colorful-wish-list"
          name="ColorfulWishList"
          width={24}
          height={24}
        />
      ) : (
        <Icon
          aria-label="Botão para remover produto da lista de favoritos"
          data-testid="wish-list-icon"
          name="WishListIcon"
          width={24}
          height={24}
        />
      )}
    </button>
  )
}
