import { useContext, useMemo } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts/product-context'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import { Image } from 'src/components/ui/Image'
import classNames from 'classnames'

import type { Variation } from '../types'

interface Props {
  variation: Variation
  onClickVariation: (name: any, value: string) => void
  items: ProductItem[]
}

type ItemByVariantName = {
  [key: string]: ProductItem
}

export function PictureSelector({ variation, onClickVariation }: Props) {
  const {
    product: { items },
  } = useContext(ProductContext)

  const variationName = variation.field.name

  const variationValuesList = useMemo(
    () => variation.values.map((variationValue) => variationValue.name),
    [variation.values]
  )

  const itemByVariantName: ItemByVariantName = useMemo(
    () =>
      variationValuesList.reduce(
        (acc, sku) => ({
          ...acc,
          [sku]:
            items.find(
              (item) =>
                item[variationName]?.[0] === sku && item.offer.hasDiscount
            ) ?? items.find((item) => item[variationName]?.[0] === sku),
        }),
        {}
      ),
    [items, variationName, variationValuesList]
  )

  const sortedVariationValues = [...variation.values].sort(
    (a, b) => (b as any).isAvailable - (a as any).isAvailable
  )

  const variationSelected = variation.values.find((item) => item.selected)

  return (
    <div className="wrapper">
      <span className="desktop-body-semibold-text3 text-restructure-secondary">
        {variationName}:{' '}
        <span className="desktop-body-regular-text3 text-restructure-secondary">
          {variationSelected?.name.split('-')[0].toLowerCase()}
        </span>
      </span>

      <ul
        className="flex items-center flex-wrap gap-sm mt-md"
        key={variation.field.id}
        data-testid="picture-selector"
      >
        {sortedVariationValues.map((variationValue, index) => (
          <li key={variationValue.id} className="selector-item">
            <button
              className={classNames(
                'bg-restructure-background-secondary relative rounded-sm h-14 w-14 border border-transparent hover:border-restructure-border-action hover:text-restructure-action',
                {
                  '!border-restructure-border-action': variationValue.selected,
                },
                {
                  '!opacity-50 cursor-not-allowed !border-none':
                    !variationValue.isAvailable,
                }
              )}
              onClick={() =>
                onClickVariation(variationName, variationValue.name)
              }
              title={variationValue.name}
              disabled={!variationValue.isAvailable}
              data-testid={`variant-color-${index}`}
            >
              <Image
                key={itemByVariantName[variationValue.name]?.images[0].imageUrl}
                srcSet={
                  itemByVariantName[variationValue.name]?.images[0].imageUrl
                }
                src={itemByVariantName[variationValue.name]?.images[0].imageUrl}
                width={42}
                height={42}
                alt={
                  itemByVariantName[variationValue.name]?.images[0].imageText
                }
                style={{ objectFit: 'contain' }}
                preload
                className="bg-restructure-background-secondary mix-blend-multiply w-full"
              />

              {itemByVariantName[variationValue.name]?.offer.hasDiscount &&
                variationValue.isAvailable && (
                  <span className="absolute -top-2 -right-2" title="liquidação">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M10.3919 0.79258C9.4882 -0.264188 7.85414 -0.264199 6.95021 0.79258L6.56487 1.24303C6.38077 1.45844 6.09125 1.55247 5.81556 1.4865L5.23911 1.34854C3.88663 1.0249 2.56475 1.98532 2.45473 3.37155L2.40773 3.96247C2.38525 4.24503 2.20649 4.4912 1.94462 4.59986L1.39711 4.82711C0.112814 5.3602 -0.39213 6.9142 0.33359 8.10038L0.643007 8.60605C0.790989 8.84782 0.790989 9.1521 0.643007 9.39394L0.33359 9.89953C-0.39213 11.0858 0.112814 12.6397 1.39711 13.1729L1.94462 13.4001C2.20649 13.5088 2.38525 13.7549 2.40773 14.0375L2.45473 14.6284C2.56475 16.0146 3.88663 16.9751 5.23911 16.6514L5.81556 16.5135C6.09125 16.4475 6.38077 16.5415 6.56487 16.7569L6.95021 17.2074C7.85414 18.2642 9.4882 18.2642 10.3919 17.2074L10.7773 16.7569C10.9616 16.5415 11.2509 16.4475 11.5266 16.5135L12.103 16.6514C13.4555 16.9751 14.7774 16.0146 14.8876 14.6284L14.9344 14.0375C14.9569 13.7549 15.1358 13.5088 15.3975 13.4001L15.945 13.1729C17.2293 12.6397 17.7343 11.0858 17.0086 9.89953L16.6991 9.39394C16.5514 9.1521 16.5514 8.84782 16.6993 8.60605L17.0086 8.10038C17.7343 6.9142 17.2293 5.3602 15.945 4.82711L15.3975 4.59986C15.1358 4.4912 14.9569 4.24503 14.9344 3.96247L14.8876 3.37155C14.7774 1.98532 13.4555 1.0249 12.103 1.34854L11.5266 1.4865C11.2509 1.55247 10.9616 1.45844 10.7773 1.24303L10.3919 0.79258Z"
                        fill="#E3262F"
                      />
                      <path
                        d="M7.838 6.8946C8.20645 6.52613 8.20645 5.9287 7.838 5.56023C7.46945 5.19176 6.87207 5.19176 6.50359 5.56023L5.56005 6.50377C5.19157 6.87226 5.19157 7.4697 5.56005 7.83815C5.92853 8.20661 6.52594 8.20661 6.89443 7.83815L7.838 6.8946Z"
                        fill="white"
                      />
                      <path
                        d="M12.5557 6.89461C12.9242 6.52613 12.9242 5.9287 12.5557 5.56023C12.1872 5.19176 11.5898 5.19176 11.2214 5.56023L5.56005 11.2215C5.19157 11.59 5.19157 12.1874 5.56005 12.5559C5.92853 12.9243 6.52594 12.9243 6.89443 12.5559L12.5557 6.89461Z"
                        fill="white"
                      />
                      <path
                        d="M12.5557 11.6123C12.9242 11.2439 12.9242 10.6464 12.5557 10.278C12.1872 9.90952 11.5898 9.90952 11.2214 10.278L10.2778 11.2215C9.90927 11.59 9.90927 12.1874 10.2778 12.5559C10.6463 12.9243 11.2436 12.9243 11.6122 12.5559L12.5557 11.6123Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
