import type { Product } from 'src/components/restructure/product/types/product'

import { slugify } from './slugfy'

type BreadcrumbList = {
  itemListElement: Array<{
    item: string
    name: string
    position: number
  }>
}

type Categories = string[]

type Offers = {
  highPrice: number
  lowPrice: number
  offers: Array<{
    availability: string
    itemCondition: string
    price: number
    priceCurrency: string
    priceValidUntil: string
    quantity?: number
    seller: { identifier: string }
  }>
  price: string
  priceCurrency: string
}

export const mapperBreadcrumbList = (
  categories?: Categories
): BreadcrumbList => {
  return {
    itemListElement:
      categories?.map((category, index) => ({
        item: slugify(category),
        name: category.split('/')[category.split('/').length - 2],
        position: index + 1,
      })) ?? [],
  }
}

const getPriceRangeGiftCard = (minPrice: number) => {
  return {
    sellingPrice: {
      highPrice: minPrice,
      lowPrice: minPrice,
    },
    listPrice: {
      highPrice: minPrice,
      lowPrice: minPrice,
    },
  }
}

const getPriceRange = ({ productId, productName, items }: any) => {
  const prices: number[] = []
  const listPrices: number[] = []

  items.forEach((item: any) =>
    item.sellers.forEach((seller: any) => {
      if (seller.commertialOffer.AvailableQuantity > 0) {
        prices.push(seller.commertialOffer.Price)
        listPrices.push(seller.commertialOffer.ListPrice)
      }
    })
  )

  if (prices.length === 0) {
    prices.push(0)
  }

  if (listPrices.length === 0) {
    listPrices.push(0)
  }

  if (productId === '11113' || productName.includes('Cartão Presente')) {
    return getPriceRangeGiftCard(Math.min(...prices))
  }

  return {
    sellingPrice: {
      highPrice: Math.max(...prices),
      lowPrice: Math.min(...prices),
    },
    listPrice: {
      highPrice: Math.max(...listPrices),
      lowPrice: Math.min(...listPrices),
    },
  }
}

export const mapperOffers = (product: any): Offers => {
  const priceRange = getPriceRange({
    productId: product.productId,
    productName: product.name ?? product.productName,
    items: product.items,
  })

  return {
    highPrice: priceRange.listPrice.highPrice,
    lowPrice: priceRange.listPrice.lowPrice,
    price: priceRange.listPrice.lowPrice.toString(),
    priceCurrency: 'BRL',
    offers: product.items.map((item: any) => {
      const sellerDefault = item.sellers.find(
        (seller: any) => seller.sellerDefault
      )

      return {
        availability:
          sellerDefault.commertialOffer.AvailableQuantity > 0
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
        itemCondition:
          product.condition === 'novo'
            ? 'https://schema.org/NewCondition'
            : 'https://schema.org/UsedCondition',
        price: sellerDefault.commertialOffer.Price,
        priceCurrency: 'BRL',
        priceValidUntil: sellerDefault.commertialOffer.PriceValidUntil,
        seller: { identifier: sellerDefault.sellerId },
      }
    }),
  }
}

const getPriceRange2 = ({ productId, productName, items }: any) => {
  const prices: number[] = []
  const listPrices: number[] = []

  items.forEach((item: any) => {
    if (item.sellerDefault.sellerOffer.isAvailable) {
      prices.push(item.sellerDefault.sellerOffer.price)
      listPrices.push(item.sellerDefault.sellerOffer.listPrice)
    }
  })

  if (prices.length === 0) {
    prices.push(0)
  }

  if (listPrices.length === 0) {
    listPrices.push(0)
  }

  if (productId === '11113' || productName.includes('Cartão Presente')) {
    return getPriceRangeGiftCard(Math.min(...prices))
  }

  return {
    sellingPrice: {
      highPrice: Math.max(...prices),
      lowPrice: Math.min(...prices),
    },
    listPrice: {
      highPrice: Math.max(...listPrices),
      lowPrice: Math.min(...listPrices),
    },
  }
}

export const mapperOffers2 = (product: Product): Offers => {
  const priceRange = getPriceRange2({
    productId: product.productId,
    productName: product.productName,
    items: product.items,
  })

  return {
    highPrice: priceRange.listPrice.highPrice,
    lowPrice: priceRange.sellingPrice.lowPrice,
    price: priceRange.sellingPrice.lowPrice.toString(),
    priceCurrency: 'BRL',
    offers: product.items.map((item) => {
      return {
        availability: item.sellerDefault.sellerOffer.isAvailable
          ? 'https://schema.org/InStock'
          : 'https://schema.org/OutOfStock',
        itemCondition:
          product.conditions?.toLowerCase() === 'novo'
            ? 'https://schema.org/NewCondition'
            : 'https://schema.org/UsedCondition',
        price: item.sellerDefault.sellerOffer.price,
        priceCurrency: 'BRL',
        priceValidUntil: item.sellerDefault.sellerOffer.priceValidUntil,
        seller: { identifier: item.sellerDefault.sellerId },
      }
    }),
  }
}
