// import { useSession } from '@faststore/sdk'
import { useCallback, useContext, useState } from 'react'
import { useUI } from 'src/sdk/ui'
import { useCart } from 'src/sdk/cart/useCart'
import type { CartItem } from 'src/sdk/cart/validate'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'
import { ProductContext } from 'src/components/restructure/product/contexts'

export interface ProductCartItem extends CartItem {
  id: string
  category: string
  price: number
  listPrice: number
  seller: {
    identifier: string
  }
  sellerName: string
  quantity: number
  productRefId: string
  arborescence: string
  productReference: string
  productId: string
  manufacturerCode: string
}

export function useBuyButton() {
  const [openModal, setOpenModal] = useState(false)

  const { specificationMustBeSelected, deliveryIsAvailable } =
    useContext(ProductContext)

  const { addItem, ...cart } = useCart()
  const { openMinicart } = useUI()

  const validateGiftCardRestrictions = useCallback(
    (item: ProductCartItem) => {
      const currentProductIsGiftCard =
        item.itemOffered.isVariantOf.productGroupID === '11113'

      const currentProductIsNotGiftCard =
        item.itemOffered.isVariantOf.productGroupID !== '11113'

      const cartContainsGiftCard = cart.items.find(
        (cartItem) =>
          cartItem.itemOffered.isVariantOf.productGroupID === '11113'
      )

      const cartContainsProductOtherThanGiftCard = cart.items.find(
        (cartItem) =>
          cartItem.itemOffered.isVariantOf.productGroupID !== '11113'
      )

      if (cartContainsProductOtherThanGiftCard && currentProductIsGiftCard) {
        setOpenModal(true)

        return true
      }

      if (cartContainsGiftCard && currentProductIsNotGiftCard) {
        setOpenModal(true)

        return true
      }

      return false
    },
    [cart.items, setOpenModal]
  )

  const addToCart = useCallback(
    (item: ProductCartItem) => {
      if (!item) {
        return
      }

      if (specificationMustBeSelected) {
        return
      }

      if (deliveryIsAvailable === false) {
        return
      }

      if (validateGiftCardRestrictions(item)) {
        return
      }

      addItem(item)
      openMinicart()
    },
    [
      addItem,
      openMinicart,
      validateGiftCardRestrictions,
      deliveryIsAvailable,
      specificationMustBeSelected,
    ]
  )

  const mapperCartItem = (
    product: Product,
    item: ProductItem,
    quantity: number
  ): ProductCartItem => {
    return {
      id: item.itemId,
      itemOffered: {
        sku: item.itemId,
        name: item.name,
        gtin: item.referenceId,
        image: item.images.map((image) => ({
          url: image.imageUrl,
          alternateName: image.imageText,
        })),
        brand: { name: product.brand },
        isVariantOf: {
          productGroupID: product.productId,
          name: product.productName,
        },
        variant: item.variations.map((variant) => ({
          name: variant,
          values: item[variant as keyof ProductItem] as string[],
        })),
        slug: product.linkText,
        additionalProperty: [],
      },
      arborescence: product.categories[0],
      category: product.sport,
      price: item.offer.price,
      listPrice: item.offer?.listPrice ?? item.offer.price,
      seller: { identifier: item.sellerDefault.sellerId },
      sellerName: item.sellerDefault.sellerName,
      productRefId: item.referenceId,
      quantity,
      productReference: product.productReference,
      productId: product.productId,
      manufacturerCode: item.manufacturerCode,
    }
  }

  return {
    addToCart,
    mapperCartItem,
    openModal,
    setOpenModal,
    // 'data-sku': item?.id,
    // 'data-seller': item?.seller.identifier,
  }
}
