import { useState } from 'react'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

const isB2B = checkEnviromentIsB2B()

const MIN_VALUE = 1
const MAX_VALUE = isB2B ? 200 : 10

export function useProductQuantity() {
  const [count, setCount] = useState(1)

  const maxValue = MAX_VALUE
  const minValue = MIN_VALUE

  function updateValueQuantity(
    value: number,
    action: 'more' | 'less' | 'input'
  ) {
    let newValue = value

    switch (action) {
      case 'less':
        newValue--
        break

      case 'more':
        newValue++
        break

      default:
        break
    }

    if (newValue < 1 || newValue > maxValue) {
      return
    }

    setCount(newValue)
  }

  return {
    count,
    updateValueQuantity,
    maxValue,
    minValue,
  }
}
