import { ProductQuantity } from 'src/components/restructure/product/sections/Sidebar/ProductAction/ProductQuantity'
import { BuyButton } from 'src/components/restructure/product/sections/Sidebar/ProductAction/BuyButton'

import { useProductQuantity } from './ProductQuantity/hooks/useProductQuantity'

export function ProductAction() {
  const { count, updateValueQuantity } = useProductQuantity()

  return (
    <section className="mt-xl mb-4">
      <div className="flex flex-col restructure-tablet:flex-row  items-center w-full gap-md restructure-tablet:gap-xs">
        <ProductQuantity
          quantity={count}
          updateValueQuantity={updateValueQuantity}
        />
        <BuyButton quantity={count} />
      </div>
    </section>
  )
}
