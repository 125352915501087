import axios from 'axios'
import { useCallback } from 'react'

export interface FreightSimulationData {
  packages: Array<{
    seller: string
    deliveryTypes: Array<{
      type: string
      value: number
      shippingEstimate: string
    }>
  }>
}

interface Item {
  id: string
  seller: string
  quantity: number
}

export function useFreightSimulation() {
  const getFreightSimulation = useCallback(
    async (items: Item[], cep: string): Promise<FreightSimulationData> => {
      const body = {
        items,
        postalCode: cep,
        country: 'BRA',
      }

      const { data } = await axios.post('/api/pdp/simulation', body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return data
    },
    []
  )

  return {
    getFreightSimulation,
  }
}
