import Icon from 'src/components/ui/Icon'

interface Props {
  zoomIn: () => void
  zoomOut: () => void
  resetTransform: () => void
  toggleThumbs: () => void
  toggleFullscreen: () => void
  isFullscreen: boolean
}

export const Controls = (props: Props) => {
  return (
    <>
      <div className="botoes flex justify-center w-full gap-2">
        <button
          className="p-4 hidden restructure-tablet:block"
          aria-label="Botão de aumentar o zoom da imagem"
          onClick={() => props.zoomIn()}
        >
          <Icon
            data-testid="icon-zoomIn"
            name="ZoomIn"
            width={24}
            height={24}
          />
        </button>
        <button
          className="p-4 hidden restructure-tablet:block"
          aria-label="Botão de diminuir o zoom da imagem"
          onClick={() => props.zoomOut()}
        >
          <Icon
            data-testid="icon-zoomOut"
            name="ZoomOut"
            width={24}
            height={24}
          />
        </button>
        <button
          className="p-4 hidden restructure-tablet:block"
          aria-label="Botão de resetar para zero o zoom da imagem"
          onClick={() => props.resetTransform()}
        >
          <Icon
            data-testid="icon-resetZoom"
            name="ResetZoom"
            width={24}
            height={24}
          />
        </button>

        <button
          className="p-4 hidden restructure-tablet:block"
          aria-label="Botão de tela cheia da Galeria Full View"
          onClick={() => props.toggleFullscreen()}
        >
          <Icon
            data-testid="icon-show-galleryfullview"
            name={props.isFullscreen ? 'FullScreen' : 'FullScreen'}
            width={24}
            height={24}
          />
        </button>
        <button
          className="p-4"
          aria-label="Botão de ocultar as miniaturas das imagens"
          onClick={() => props.toggleThumbs()}
        >
          <Icon
            data-testid="icon-close-galleryfullview"
            name="HideThumbs"
            width={24}
            height={24}
          />
        </button>
      </div>
    </>
  )
}
