import classNames from 'classnames'
import { useState, useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import { Shipping } from './Shipping'
import { Description } from './Description'

type ActiveTabState = 'shipping' | 'description'

export function TabbedNavigation() {
  const {
    product: { description },
  } = useContext(ProductContext)

  const [activeTab, setActiveTab] = useState<ActiveTabState>('shipping')

  const COMPONENTS = {
    shipping: Shipping,
    description: Description,
  }

  const Component = COMPONENTS[activeTab]

  const props = {
    description,
  }

  const shippingEventTrack = makeEventTrack({
    eventAction: 'Frete e retirada',
    eventPage: 'pagina de produto',
  })

  const descriptionEventTrack = makeEventTrack({
    eventAction: 'Descrição',
    eventPage: 'pagina de produto',
  })

  return (
    <section className="mt-lg">
      <ul className="flex items-center gap-lg">
        <li>
          <button
            onClick={() => {
              sendEvent(shippingEventTrack)
              setActiveTab('shipping')
            }}
            className={classNames('desktop-body-regular-text2 pb-xs', {
              'border-b border-restructure-border-information desktop-body-semibold-text2 text-restructure-action':
                activeTab === 'shipping',
            })}
          >
            Frete e retirada
          </button>
        </li>
        {description !== '' && (
          <li>
            <button
              onClick={() => {
                sendEvent(descriptionEventTrack)
                setActiveTab('description')
              }}
              className={classNames('desktop-body-regular-text2 pb-xs', {
                'border-b border-restructure-border-information desktop-body-semibold-text2 text-restructure-action':
                  activeTab === 'description',
              })}
            >
              Descrição
            </button>
          </li>
        )}
      </ul>

      <div className="scroll-bar-gray mt-md pb-lg border-b border-restructure-border-secondary max-h-[286px] overflow-y-auto pr-xxs">
        <Component {...props} />
      </div>
    </section>
  )
}
