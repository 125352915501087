import type { Product } from 'src/components/restructure/product/types/product'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'

type MapperEventItemProps = {
  product: Product
  item: ProductItem
}

export const mapperEventItem = ({ product, item }: MapperEventItemProps) => {
  return {
    item_id: item.itemId,
    item_name: item.name,
    affiliation: item.sellerDefault.sellerName,
    price: item.sellerDefault.sellerOffer.price,
    quantity: item.sellerDefault.sellerOffer.quantity,
    discount: item.sellerDefault.sellerOffer.discount,
    index: 0,
    coupon: 'NA',
    item_brand: product.brand,
    arborescence: product.categories[0],
    item_category: item.sellerDefault.sellerId,
    item_category2: product.sport,
    item_category3: product.productId,
    item_category4: item.itemId,
    item_category5: item.referenceId,
    item_list_name: 'NA',
    item_list_id: 'NA',
    item_variant: item?.Cor?.[0] ?? 'NA',
  }
}
