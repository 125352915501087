export const slugify = (str?: string, replaceSpace = '-') => {
  if (!str) {
    return ''
  }

  const newStr = str.toLowerCase().replace(/\s/g, replaceSpace)

  return newStr
    .replace(/[`]/g, '')
    .replace(/[àáâãäå]/g, 'a')
    .replace(/[éèêë]/g, 'e')
    .replace(/[íìîï]/g, 'i')
    .replace(/[òóôõö]/g, 'o')
    .replace(/[ùúûü]/g, 'u')
    .replace(/[ỳýŷỹ]/g, 'y')
    .replace(/[ç]/g, 'c')
}
