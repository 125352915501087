import { useContext } from 'react'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import ProductCard from 'src/components/product/ProductCardV2'
import Section from 'src/components/sections/Section'
import { RecommendationContext } from 'src/components/restructure/product/contexts/recommendations-context'

import ShelfBoughtTogetherFooter from './ShelfBoughtTogetherFooter/ShelfBoughtTogetherFooter'
import './index.scss'
import { useShelfBoughtTogether } from './useShelfBoughtTogether'

export function ShelfBoughtTogether() {
  const { loading } = useContext(RecommendationContext)

  const {
    dataLoaded,
    itemsActive,
    currentItem,
    currentItemSku,
    decimalValue,
    centsValue,
    inst,
    handleChangedItemArraySKU,
    handleDeleteCard,
    handleAddItemsToCart,
    handleReloadCard,
    checkQuantity,
    checkItemsActiveLength,
  } = useShelfBoughtTogether()

  return (
    <>
      {checkQuantity() && loading && (
        <>
          {dataLoaded ? (
            <Section className="max-w-[1280px] m-auto px-md my-[40px] restructure-tablet:my-[48px] restructure-small-desktop:my-[60px]">
              <h2 className="mobile-heading-title3 tablet-heading-title3 desktop-heading-title3 text-restructure-primary mb-6 restructure-medium-desktop:mb-8">
                Compre junto
              </h2>
              <div className="section-bought-together-list flex gap-[32px]  restructure-tablet:gap-[32px] restructure-medium-desktop:gap-[24px] justify-center restructure-medium-desktop:justify-start items-start flex-col restructure-medium-desktop:flex-row">
                <ul className="new-bought-together-list pb-[25px] px-0 max-w-[100%] overflow-x-auto overflow-y-hidden justify-start flex gap-[10px] restructure-tablet:gap-[24px] restructure-medium-desktop:justify-center w-full restructure-medium-desktop:w-[unset]">
                  <li className={`item-${itemsActive.length + 1}`}>
                    <ProductCard
                      product={currentItem}
                      index={Number(currentItem.itemId)}
                      skuItem={currentItemSku[0]}
                      stylesBoughtTogether
                      isBoughtTogether
                      notSelector
                    />

                    <p className="text-restructure-tertiary text-center mt-1 mobile-caption-regular desktop-body-regular-text3 desktop-body-regular-text3">
                      Você está vendo este produto
                    </p>
                  </li>
                  {itemsActive.map((product, idx: number) => (
                    <li
                      key={product.productId}
                      className={`item-${itemsActive.length + 1}`}
                    >
                      <ProductCard
                        product={product}
                        index={idx}
                        handleChangedItemSKU={handleChangedItemArraySKU}
                        isBoughtTogether
                        stylesBoughtTogether
                        itemsActive={itemsActive}
                      />
                      <ShelfBoughtTogetherFooter
                        onDeleteCard={() => handleDeleteCard(product.productId)}
                        onReloadCard={() => handleReloadCard(product.productId)}
                      />
                    </li>
                  ))}
                </ul>

                <div
                  className={`${checkItemsActiveLength(
                    itemsActive.length
                  )}  gap-y-2 flex flex-col justify-center w-full restructure-medium-desktop:w-none max-w-none restructure-medium-desktop:min-w-[303px] restructure-medium-desktop:max-w-[303px]`}
                >
                  <div className="flex-col restructure-tablet:flex-row restructure-medium-desktop:flex-col border border-solid border-restructure-border-secondary rounded-md p-4 restructure-medium-desktop:py-10 restructure-medium-desktop:px-8 flex justify-between items-center restructure-medium-desktop:block">
                    <p className="mb-4 restructure-tablet:mb-0 restructure-medium-desktop:mb-4 text-restructure-secondary mobile-heading-title5 tablet-heading-title5 desktop-heading-title5 text-center">
                      Que tal levar os {itemsActive.length + 1} produtos?
                    </p>
                    <div>
                      <p className="flex justify-center mb-2">
                        <span className="mobile-heading-title3 tablet-heading-title3 desktop-heading-title3 uppercase">
                          R$ {decimalValue},{centsValue?.slice(0, 2)}
                        </span>
                      </p>
                      <p className="mb-8 restructure-tablet:mb-0 restructure-medium-desktop:mb-8 underline text-center text-restructure-tertiary mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3">
                        {inst && inst.installment}x R${' '}
                        {inst && inst.installmentPrice}
                      </p>
                    </div>
                    <button
                      className="flex justify-center items-center gap-2 w-full rounded-round py-3 px-12 max-w-[239px] text-restructure-primary-inverted bg-restructure-background-action-1 hover:bg-restructure-background-action-1-hover active:bg-restructure-background-action-3 disabled:bg-restructure-background-action-4"
                      data-store-buy-button
                      name="buy together"
                      aria-label="buy together"
                      onClick={() => handleAddItemsToCart()}
                    >
                      Comprar junto
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.04102 10C4.04102 9.65482 4.32084 9.375 4.66602 9.375H16.3327C16.6779 9.375 16.9577 9.65482 16.9577 10C16.9577 10.3452 16.6779 10.625 16.3327 10.625H4.66602C4.32084 10.625 4.04102 10.3452 4.04102 10Z"
                          fill="white"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.0581 3.72456C10.3021 3.48048 10.6979 3.48048 10.9419 3.72456L16.7753 9.5579C17.0194 9.80197 17.0194 10.1977 16.7753 10.4418L10.9419 16.2751C10.6979 16.5192 10.3021 16.5192 10.0581 16.2751C9.81398 16.031 9.81398 15.6353 10.0581 15.3912L15.4495 9.99984L10.0581 4.60845C9.81398 4.36437 9.81398 3.96864 10.0581 3.72456Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </Section>
          ) : (
            <ProductShelfSkeleton />
          )}
        </>
      )}
    </>
  )
}
