import type { Options } from '@splidejs/splide'

export const mainOptionsGallery = {
  speed: 800,
  type: 'loop',
  perPage: 1,
  perMove: 1,
  pagination: false,
  arrows: false,
  gap: '10px',
  heightRatio: 5,
  slideFocus: true,
  drag: false,
  breakpoints: {
    4000: {},
    600: {
      arrows: false,
      drag: true,
    },
  },
}

export const thumbsOptionsGallery: Options = {
  speed: 300,
  type: 'slide',
  rewind: false,
  gap: '24px',
  pagination: false,
  cover: false,
  focus: 0,
  direction: 'ltr',
  heightRatio: 2,
  perPage: 1,
  perMove: 1,
  isNavigation: true,
  arrows: true,
  fixedHeight: 88,
  fixedWidth: 88,
  slideFocus: true,
  breakpoints: {
    600: {
      gap: '17px',
      arrows: false,
    },
  },
}
