type Item = {
  item_id: string
  item_name: string
  affiliation: string
  coupon: string
  discount: number
  index: number
  item_brand: string
  arborescence: string
  item_category: string
  item_category2: string
  item_category3: string
  item_category4: string
  item_category5: string
  item_list_name: string
  item_list_id: string
  item_variant: string
  price: number
  quantity: number
}

export const sendWishlistEvent = (item: Item): void => {
  try {
    if (typeof window !== 'undefined' && typeof document === 'object') {
      const event = {
        event: 'add_to_wishlist',
        ecommerce: {
          currency: 'BRL',
          value: item.price,
          items: [item],
        },
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(event)
    }
  } catch {
    throw new Error('Object for dataLayer is undefined.')
  }
}
