import { createContext, useContext, useMemo, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { ShippingSimulation } from 'src/components/restructure/common/FreightSimulation/types'

type ShippingProviderProps = {
  children: React.ReactNode
}

type ShippingContextProps = {
  simulation: ShippingSimulation | null
  setSimulation: Dispatch<SetStateAction<ShippingSimulation | null>>
  isSimulatingShipping: boolean
  setIsSimulatingShipping: Dispatch<SetStateAction<boolean>>
}

export const ShippingContext = createContext<ShippingContextProps>(
  {} as ShippingContextProps
)

export const ShippingProvider = ({ children }: ShippingProviderProps) => {
  const [simulation, setSimulation] = useState<ShippingSimulation | null>(null)
  const [isSimulatingShipping, setIsSimulatingShipping] = useState(false)

  const shippingContextValue = useMemo(
    () => ({
      simulation,
      setSimulation,
      isSimulatingShipping,
      setIsSimulatingShipping,
    }),
    [simulation, setSimulation, isSimulatingShipping, setIsSimulatingShipping]
  )

  return (
    <ShippingContext.Provider value={shippingContextValue}>
      {children}
    </ShippingContext.Provider>
  )
}

export const useShippingContext = () => useContext(ShippingContext)
