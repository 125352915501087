import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import Icon from 'src/components/ui/Icon'
import { sendEvent, makeEventTrack } from 'src/utils/restructure/analytics'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

import { WishListButton } from './WishListButton'

export function ProductTitle() {
  const { product } = useContext(ProductContext)
  const { brand, productName, review } = product

  const isB2B = checkEnviromentIsB2B()

  const handleClick = () => {
    const eventTrack = makeEventTrack({
      eventAction: 'ver todas as avaliações',
      eventPage: 'pagina de produto',
    })

    sendEvent(eventTrack)

    const sectionCustomerElement = document?.getElementById(
      'customer_reviews_section'
    )

    sectionCustomerElement?.scrollIntoView({
      block: 'start',
      inline: 'nearest',
    })
  }

  return (
    <section className="flex justify-between items-start">
      <div>
        <p className="desktop-body-regular-text3 text-restructure-tertiary">
          {brand}
        </p>
        <h1 className="desktop-body-regular-text1 text-restructure-primary mb-0">
          {productName}
        </h1>
        {review && (
          <button
            className="flex items-center gap-xxs mt-xs"
            onClick={handleClick}
          >
            <Icon
              aria-label="Botão para acessar mais detalhes sobre avaliações do produto"
              data-testid="star-icon"
              name="StarIcon"
              width={15}
              height={15}
            />
            <p
              className="desktop-body-regular-text3 text-restructure-secondary"
              data-testid="ratings-average-note"
            >
              {`${review.totalRatingsAverageNote.toFixed(1)}/5`}
            </p>
            <span
              className="desktop-body-regular-text3 text-restructure-action"
              data-testid="total-reviews"
            >
              {`(${review.totalReviews})`}
            </span>
          </button>
        )}
      </div>
      {!isB2B && <WishListButton />}
    </section>
  )
}
